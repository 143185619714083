<template>
  <div class="mod-config">
    <el-card
      body-style="padding-bottom: 0;"
      style="margin-top: -5px; min-height: 670px"
    >
      <div class="title">总体计划节点与实际进度对比</div>
      <div class="line" style="margin-bottom:0px"></div>
		<el-table max-height="600" :row-class-name="tableRowClassName" :span-method="objectSpanMethod"
			ref="dataListTable" class="dataListTable" border :data="dataList"
			header-align="center" header-cell-class-name="dataListTHeader"
			:cell-style="{ padding: '0px' }" style="margin-bottom: 20px;">
			<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '0'"
				:label="v.nodeName" :prop="v.nodeId" align="center" width="130px">
					<template slot-scope="scope">
					<span style="font-weight:bolder">
						{{scope.row.subjectName }}
					</span>
					</template>
			</el-table-column>
			<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '1'"
				:label="v.nodeName" :prop="v.nodeId" align="center" width="60px">
					<template slot-scope="scope">
					<span style="font-weight:bolder">
						{{scope.row.title}}
					</span>
					</template>
			</el-table-column>
			<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && idx > 1"
				:label="v.nodeName" :prop="v.nodeId" align="center">
					<template slot-scope="scope">
					<span v-if="!getDisabled(scope.row.recNo)">
						{{scope.row.contentList[idx-2].colValue }}
					</span>
					<el-input v-if="getDisabled(scope.row.recNo)" class="sp-input"
						v-model="scope.row.contentList[idx-2].colValue"></el-input>
					</template>
					<template slot="header" slot-scope="scope">
						{{v.nodeName}}
						<el-tooltip :aa="scope" class="item" effect="dark" content="修改分项节点数据" placement="top-start">
							<i class="el-icon-edit-outline" @click="editSubNodeHandle(v)"></i>
						</el-tooltip>
					</template>
			</el-table-column>
			<el-table-column align="left" label="" v-if="dataList != undefined && dataList.length > 0 && headers != undefined && headers.length == 2">
			</el-table-column>
			<el-table-column align="left" label="" width="130px" v-if="dataList != undefined && dataList.length > 0 && headers != undefined && headers.length > 2">
				<template slot-scope="scope">
					<el-button type="text" size="small" v-if="!updateFlg && !addFlg"
						@click="updateHandle(scope.row.recNo)">修改</el-button>
					<el-button type="text" size="small" v-if="!updateFlg && !addFlg && scope.row.recType === '0'"
						@click="addChangeHandle(scope.row)">添加变更</el-button>
					<el-button type="text" size="small" v-if="!updateFlg && !addFlg && scope.row.recType=== '1'"
						@click="deleteHandle(scope.row.recNo)">删除</el-button>
					<!--新增变更时按钮-->
					<el-button v-preventReClick type="text" size="small"
						@click="saveRecordHandle(scope.row)"
						v-if="getDisabled(scope.row.recNo) && addFlg">保存</el-button>
					<el-button v-preventReClick type="text" size="small"
						@click="cancelSaveHandle()"
						v-if="getDisabled(scope.row.recNo) && addFlg">取消</el-button>	
					<!--修改时按钮-->
					<el-button v-preventReClick type="text" size="small"
						@click="updateRecordHandle(scope.row)"
						v-if="getDisabled(scope.row.recNo) && updateFlg">保存</el-button>
					<el-button v-preventReClick type="text" size="small"
						@click="cancelUpdateHandle()"
						v-if="getDisabled(scope.row.recNo) && updateFlg">取消</el-button>
				</template>
			</el-table-column>
		</el-table>
    </el-card>
	<edit-sub-node v-if="editSubNodeVisible" ref="editSubNode" @refreshDataList="getDataList"></edit-sub-node>
  </div>
</template>
<script>
import $common from "@/utils/common.js"
import editSubNode from './edit-sub-node.vue'
export default {
  data() {
    return {
      pageSpan: 12,
	  addFlg : false,
      updateFlg: false,
	  editSubNodeVisible: false,
      form: {},
      workNo: $common.getItem("workNo"),
      dataList: [],
	  headers: [],
      list: [],
	  updateRecNo:'',
    };
  },
  components: {
	  editSubNode
  },
  mounted() {
    // this.getDataList();
	this.refreshNodeRecords();
  },
  methods: {
	getDisabled(val) {
		if (this.updateRecNo === val && this.updateFlg) {
			return true;
		} else if(val === '' && this.addFlg){
			return true;
		}
		return false;
	},
	editSubNodeHandle(header){
		this.editSubNodeVisible = true
		this.$nextTick(() => {
			this.$refs.editSubNode.init(header)
		})
	},
	updateHandle(val) {
		this.updateRecNo = val
		this.updateFlg = true;
	},
	cancelUpdateHandle() {
		this.updateFlg = false;
		this.updateRecNo = ''
		this.getDataList()
	},
	addChangeHandle(val) {
		this.addFlg = true;
		var maxRecSort = 0;
		var contentList = [];

		// 组装节点数据列表
		for (var j = 0; j < this.headers.length; j++) {
			if(this.headers[j].nodeId != '0' && this.headers[j].nodeId != '1') {
				let content = {
					colName: this.headers[j].nodeId,
					colValue: ''
				}
				contentList.push(content);
			}
		}

		// 获取当前现场变更数据排序最大值
		for (var i = 0; i < this.dataList.length; i++) {
			if(this.dataList[i].subjectNo === val.subjectNo) {
				if(this.dataList[i].recType === '1' && this.dataList[i].recSort > maxRecSort) {
					maxRecSort = this.dataList[i].recSort;
				} 
			}
		}

		let changeData = {
			subjectNo: val.subjectNo,
			subjectName: val.subjectName,
			recNo: '',
			title: '变更',
			recType: '1',
			recSort: maxRecSort + 1,
			contentList : contentList
		}

		// 插入数据
		var records = [];
		for (var i = 0; i < this.dataList.length; i++) {
			if(this.dataList[i].subjectNo === val.subjectNo) {
				if(this.dataList[i].recType === '0' || this.dataList[i].recType === '1') {
					records.push(this.dataList[i]);
				} else {
					records.push(changeData)
					records.push(this.dataList[i]);
				}
			} else {
				records.push(this.dataList[i]);
			}
		}
		this.dataList = records;
	},
	cancelSaveHandle() {
		this.addFlg = false;
		this.getDataList();
	},
	deleteHandle(recNo){
		this.$http({
			url: this.$store.state.httpUrl + "/business/projectprogressnoderecord/delete/" + recNo,
			method: 'post',
			params: this.$http.adornParams({
			})
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.getDataList();	
			}
		})
	},
	saveRecordHandle(val) {
		let _url = "/business/projectprogressnoderecord/insert";
		this.$http({
			url: this.$store.state.httpUrl + _url,
			method: "post",
			data: this.$http.adornData({
				subjectNo: val.subjectNo,
				workNo: $common.getItem("workNo"),
				recType: val.recType,
				recSort: val.recSort,
				contentList: val.contentList
			})
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.$message({
					message: "操作成功",
					type: "success",
					duration: 1500,
				});
				this.getDataList()
				this.addFlg = false
			}
		});
	},
	updateRecordHandle(val) {
		let _url = "/business/projectprogressnoderecord/update";
		this.$http({
			url: this.$store.state.httpUrl + _url,
			method: "post",
			data: this.$http.adornData(val)
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.$message({
					message: "操作成功",
					type: "success",
					duration: 1500,
				});
				this.getDataList()
				this.updateFlg = false
				this.updateRecNo = '';
			}
		});
	},
	refreshNodeRecords() {
		this.$http({
			url: this.$store.state.httpUrl + "/business/projectprogressnoderecord/refreshNodeRecords/" + $common.getItem("workNo"),
			method: 'post',
			params: this.$http.adornParams({
			})
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.getDataList();	
			}
		})
	},
	flitterSubjectNo (arr) {
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          //name 修改
          if (item.subjectName === arr[index - 1].subjectName) { //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          };
        }
      });
      return {
        one: spanOneArr,
      }
    },
	flitterData (arr) {
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          //name 修改
          if (item.title === arr[index - 1].title) { //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          };
        }
      });
      return {
        one: spanOneArr,
      }
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // this.tableData  修改
        const _row = (this.flitterSubjectNo(this.dataList).one)[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      } else if(columnIndex === 1){
 		// this.tableData  修改
        const _row = (this.flitterData(this.dataList).one)[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
	  }
    },
    // 获取数据列表
    getDataList() {
      this.$http({
        url: this.$store.state.httpUrl + "/business/projectprogressnoderecord/list/" + $common.getItem("workNo"),
        method: 'get',
        params: this.$http.adornParams({
        })
      }).then(({
        data
      }) => {
        if (data && data.resultCode === 200) {
          this.dataList = data.body.records;
		  this.headers = data.body.headers;
        }
      })
    },
	tableRowClassName({ row, rowIndex }) {
		return $common.tableScnenRowClassName({ row, rowIndex });
	},
  }
};
</script>
<style scoped>
.sp-input /deep/ .el-input__inner {
    width: 100% !important;
}

.el-icon-edit-outline {
	cursor: pointer;
}
</style>
